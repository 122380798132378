import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import Comingup from "./components/comingup";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";

ReactGA.initialize("UA-202497767-1");
ReactGA.pageview(window.location.pathname + window.location.search);
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,

  document.getElementById("root")
);
